import styled from "styled-components";
import { ImagePlacement } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { alignmentOptionsToValue } from "../../composeCss";
import { IBoxArticleListSettings } from "./StyledBoxArticleList.types";

export const generateBoxArticleListStyles = ({
  $settings,
}: {
  $settings: IBoxArticleListSettings["$settings"];
}) => {
  return `
    display: flex;
    flex-direction: column;
    gap: 30px;

    & .article,
    & .article-list__slider__single-slide__content {
      display: flex;
      ${
        $settings.image.alignment === ImagePlacement.LEFT
          ? "flex-direction: row-reverse;"
          : ""
      }
      ${
        $settings.image.alignment === ImagePlacement.RIGHT
          ? "flex-direction: row;"
          : ""
      }
      ${
        $settings.image.alignment === ImagePlacement.TOP
          ? `flex-direction: column;
          `
          : ""
      }

      overflow: hidden;
      width: 100%;
    }

    & a {
      display: none;
    }

    & a:not(:empty) {
      display: flex;
      justify-content: ${alignmentOptionsToValue(
        $settings.article.contentAlignment
      )};
      width: 100%;
      height: 100%;
    }

    ${
      $settings.order[$settings.order.length - 1] === "image" &&
      `
    & img {
      margin-top: auto;
    }
    `
    }

    .article-list__heading {
      ${
        $settings.sliderPaginationStyle === "ARROWS"
          ? `margin-left: ${$settings.spacing.horizontal}px`
          : ""
      }
    }
  `;
};

export const StyledBoxArticleList = styled.div<IBoxArticleListSettings>(
  (props) => generateBoxArticleListStyles(props)
);
