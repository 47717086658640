import React, { useMemo, useState } from "react";
import debounce from "lodash.debounce";
import ChevronRightIcon from "../../ChevronRightIcon/ChevronRightIcon";
import { PaginationWrapper } from "../Pagination.styled";

interface PaginationWithInputProps {
  currentPage: number;
  setCurrentPage: (v: number) => void;
  pageCount: number;
  customStyles?: string;
}

const PaginationWithInput: React.FC<PaginationWithInputProps> = ({
  currentPage,
  setCurrentPage,
  pageCount,
  customStyles,
}) => {
  const lastPageIndex = pageCount;
  const hasNextPage = currentPage < lastPageIndex;

  const [input, setInput] = useState<number | "">(currentPage);

  const inputChange = (index: string) => {
    if (index === "") {
      setInput(index);
    } else if (
      index.match(new RegExp("^[0-9]+$")) &&
      +index <= lastPageIndex &&
      +index > 0
    ) {
      setInput(+index);
      handlePageSelection(+index);
    }
  };

  const handlePageSelection = useMemo(
    () =>
      debounce((index: number) => {
        setCurrentPage(index);
      }, 500),
    []
  );

  return (
    <PaginationWrapper
      customStyles={customStyles}
      className="pagination-container"
    >
      {+input > 1 && (
        <button
          className="pagination-container__chevron-icon"
          data-testid="btn-prev"
          onClick={() => inputChange(String(+input - 1))}
        >
          <ChevronRightIcon />
        </button>
      )}
      <input
        className="pagination-container__input"
        value={input}
        max={lastPageIndex}
        min={1}
        onChange={(e) => inputChange(e.target.value)}
      />
      <p>z</p>
      <p>{lastPageIndex}</p>
      {hasNextPage && (
        <button
          data-testid="btn-next"
          onClick={() => inputChange(String(+input + 1))}
        >
          <ChevronRightIcon />
        </button>
      )}
    </PaginationWrapper>
  );
};

export default PaginationWithInput;
