import styled from "styled-components";

export const PaginationWrapper = styled.div<{ customStyles?: string }>(
  ({ customStyles }) => `
      display: flex;
      align-items: center;
      gap: 16px;
      margin-top: 15px;
      justify-content: flex-end;
      ${customStyles ?? ""}

      button:disabled > svg > path {
        fill: #cccccc;
      }

      .pagination-container {
        &__double-chevron-icon, &__chevron-icon {
          transform: rotate(180deg);
        }

        &__input {
          padding: 0.5rem 0.75rem;
          width: 4rem;
          border-width: 1px;
          text-align: center;
        }
      
        &__range-container {
          display: flex;
          gap: 5px;
          
          &__dots {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 4px;
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 600;
          }
        }
      }
    `
);
