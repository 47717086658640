export const paginationStyle = `
width: 100%;
grid-column: 1/-1;
justify-content: center;

[data-id="pagination-btn"], [data-id="pagination-btn-active"] {
  width: 20px;
  height: auto;
  border-radius: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  user-select: none;
}

[data-id="pagination-btn"] {
  color: #81889c;
  cursor: pointer;
  opacity: 1;
  transition: all var(--ecp-transition);
}

[data-id="pagination-btn"]:not([data-id="pagination-btn-active"]):hover {
  background-color: #f0f5fe;
}

[data-id="pagination-btn"]:not([data-id="pagination-btn-active"]):active {
  background-color: #dce9fd;
}

[data-id="pagination-btn-active"] {
  color: #293740;
  cursor: auto;
}
`;
